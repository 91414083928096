import React from 'react';

import EditAddWorkout from '../../components/editAddWorkout';

const AddWorkoutPage: React.FC = () => {
    return (
        <EditAddWorkout />
    )
}

export default AddWorkoutPage;