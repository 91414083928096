export const GET_EQUIPMENT = 'GET_EQUIPMENT';
export const GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS';

export const GET_WORKOUT_LEVELS = 'GET_WORKOUT_LEVELS';
export const GET_WORKOUT_LEVELS_SUCCESS = 'GET_WORKOUT_LEVELS_SUCCESS';

export const GET_COACHES_FILTER = 'GET_COACHES_FILTER';
export const GET_COACHES_FILTER_SUCCESS = 'GET_COACHES_FILTER_SUCCESS';

export const GET_WORKOUT_TYPES_FILTER = 'GET_WORKOUT_TYPES_FILTER';
export const GET_WORKOUT_TYPES_FILTER_SUCCESS = 'GET_WORKOUT_TYPES_FILTER_SUCCESS';

export const GET_ALL_FILTER_DATA = 'GET_ALL_FILTER_DATA';

export const SELECT_FILTERS = 'SELECT_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';